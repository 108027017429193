@charset "UTF-8";

/********** 初始化化样式 **********/

* {
  margin: 0;
  padding: 0;
}

body {
  color: #282828;
  font-size: 0.875rem;
  font-family: 微软雅黑;
  font-style: normal;
}

@font-face {
  font-family: 'BigruixianBoldkGBV10';
  src: url("../img/BigruixianBoldkGBV10.TTF");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'BigYoungMediumGB20';
  src: url("../img/BigYoungMediumGB20.TTF");
  font-style: normal;
  font-weight: normal;
}

a,
a:hover {
  -webkit-transition: background 0.5s linear;
  transition: background 0.5s linear;
}

a {
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

em,
i {
  font-style: normal;
}

ul,
ol,
li {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

input,
select,
textarea {
  outline: none;
  border: none;
  background: none;
}

textarea {
  resize: none;
}

p {
  line-height: 1.375rem;
}

/********** 通用类定义 **********/

.cp {
  cursor: pointer !important;
}

.fcnormal {
  color: #282828;
}

.fcgray {
  color: #666;
}

.fcgrayer {
  color: #999;
}

.f12 {
  font-size: 0.75rem !important;
}

.f14 {
  font-size: 0.875rem !important;
}

.f16 {
  font-size: 1rem !important;
}

.f18 {
  font-size: 1.125rem !important;
}

.f22 {
  font-size: 1.375rem !important;
}

.f26 {
  font-size: 1.625rem !important;
}

.mo {
  padding: 0 !important;
}

.mn {
  margin: 0.3125rem !important;
}

.mm {
  margin: 0.625rem !important;
}

.mw {
  margin: 1.25rem !important;
}

.mv {
  margin: 1.875rem !important;
}

.mtn {
  margin-top: 0.3125rem !important;
}

.mtm {
  margin-top: 0.625rem !important;
}

.mtw {
  margin-top: 1.25rem !important;
}

.mtv {
  margin-top: 1.875rem !important;
}

.mbn {
  margin-bottom: 0.3125rem !important;
}

.mbm {
  margin-bottom: 0.625rem !important;
}

.mbw {
  margin-bottom: 1.25rem !important;
}

.mbv {
  margin-bottom: 1.875rem !important;
}

.mrn {
  margin-right: 0.3125rem !important;
}

.mrm {
  margin-right: 0.625rem !important;
}

.mrw {
  margin-right: 1.25rem !important;
}

.mrv {
  margin-right: 1.875rem !important;
}

.mln {
  margin-left: 0.3125rem !important;
}

.mlm {
  margin-left: 0.625rem !important;
}

.mlw {
  margin-left: 1.25rem !important;
}

.mlv {
  margin-left: 1.875rem !important;
}

.mtbn {
  margin: 0.3125rem 0 !important;
}

.mtbm {
  margin: 0.625rem 0 !important;
}

.mtbw {
  margin: 1.25rem 0 !important;
}

.mtbv {
  margin: 1.875rem 0 !important;
}

.mlrn {
  margin: 0 0.3125rem !important;
}

.mlrm {
  margin: 0 0.625rem !important;
}

.mlrw {
  margin: 0 1.25rem !important;
}

.mlrv {
  margin: 0 1.875rem !important;
}

.po {
  padding: 0 !important;
}

.pn {
  padding: 0.3125rem !important;
}

.pm {
  padding: 0.625rem !important;
}

.pw {
  padding: 1.25rem !important;
}

.pv {
  padding: 1.875rem !important;
}

.ptn {
  padding-top: 0.3125rem !important;
}

.ptm {
  padding-top: 0.625rem !important;
}

.ptw {
  padding-top: 1.25rem !important;
}

.ptv {
  padding-top: 1.875rem !important;
}

.pbn {
  padding-bottom: 0.3125rem !important;
}

.pbm {
  padding-bottom: 0.625rem !important;
}

.pbw {
  padding-bottom: 1.25rem !important;
}

.pbv {
  padding-bottom: 1.875rem !important;
}

.pln {
  padding-left: 0.3125rem !important;
}

.plm {
  padding-left: 0.625rem !important;
}

.plw {
  padding-left: 1.25rem !important;
}

.plv {
  padding-left: 1.875rem !important;
}

.prn {
  padding-right: 0.3125rem !important;
}

.prm {
  padding-right: 0.625rem !important;
}

.prw {
  padding-right: 1.25rem !important;
}

.prv {
  padding-right: 1.875rem !important;
}

.ptbn {
  padding: 0.3125rem 0 !important;
}

.ptbm {
  padding: 0.625rem 0 !important;
}

.ptbw {
  padding: 1.25rem 0 !important;
}

.ptbv {
  padding: 1.875rem 0 !important;
}

.plrn {
  padding: 0 0.3125rem !important;
}

.plrm {
  padding: 0 0.625rem !important;
}

.plrw {
  padding: 0 1.25rem !important;
}

.plrv {
  padding: 0 1.875rem !important;
}

.linkp {
  color: #47BAEE !important;
}

.linkp:hover {
  color: #5BCCFF !important;
}

.linkd {
  color: #ED533A !important;
}

.linkd:hover {
  color: #FF7761 !important;
}

.iconfont {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.hidden-o,
.show-o {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.hidden {
  display: none;
}

.hidden-o {
  opacity: 0;
}

.show {
  display: block;
}

.show-o {
  opacity: 1;
}