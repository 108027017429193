/* 浮动设置 */

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.recruitment-tabbar {
  width: 100%;
  height: 27.5rem;
  margin: 0 auto;
  background-image: url('../../assets/img/recruitment/bn_recruitment.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* 福利待遇及薪酬 */

.benefits {
  width: 100%;
  height: 46.25rem;
  margin-top: 7.8125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 3.125rem;
  background-image: url('../../assets/img/recruitment/welfare_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.benefits-text {
  height: 0.875rem;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin: 6.3125rem 0 1.4375rem 29.375rem;
  letter-spacing: 0.125rem;
}

.benefits-wrapper {
  margin: 0 0 3.625rem 25rem;
}

.gift {
  display: inline-block;
  vertical-align: text-bottom;
  width: 2.875rem;
  height: 2.625rem;
  margin-right: 1.4375rem;
}

.benefits-title {
  display: inline;
  font-size: 2.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
}

.item-wrapper {
  margin-left: 29.4375rem;
}

.item-icon {
  display: inline-block;
  width: auto;
  height: 100%;
  margin-right: 1.75rem;
  margin-bottom: 2.5rem;
}

.item-icon-1 {
  width: 2rem;
  height: 2.3125rem;
  margin-right: 2.25rem;
}

.item-icon-2 {
  width: 2.5rem;
  height: 2.0625rem;
}

.item-icon-3 {
  width: 2.3125rem;
  height: 2.25rem;
}

.item-icon-4 {
  width: 2.375rem;
  height: 2.1875rem;
}

.item-icon-5 {
  width: 2.1875rem;
  height: 2.25rem;
  margin-right: 1.875rem;
}

.item-desc {
  display: inline-block;
  vertical-align: top;
  font-size: 1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
  white-space: pre;
}

.item-desc-1 {
  margin-top: 0.3125rem;
}

.item-desc-2 {
  margin-top: 0.0625rem;
}

.item-desc-3 {
  margin-top: 0.3125rem;
}

.item-desc-4 {
  margin-top: -0.5625rem;
}

.item-desc-5 {
  margin-top: 0.40625rem;
}

@media screen and (max-width: 1300px) {
  .benefits {
    height: 48.75rem;
  }

  .benefits-text {
    margin: 5.125rem 0 1.4375rem 29.375rem;
  }

  .benefits-wrapper {
    margin: 0 0 3rem 25rem;
  }

  .item-icon {
    margin-bottom: 2.25rem;
  }
}

/* 招聘流程 */

.recruitment-wrapper {
  width: 100%;
  height: 44.0625rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 5.625rem;
  background-color: #fff;
}

.contact-box {
  text-align: center;
  margin-bottom: 9.375rem;
}

.step-container {
  margin: 0 auto;
  text-align: center;
  margin: 6.25rem 0 3.75rem 0;
}

.step-wrapper {
  display: inline-block;
  vertical-align: top;
}

.step-box {
  display: inline-block;
  height: 10.125rem;
}

.step-flow-icon-1,
.step-flow-icon-3,
.step-flow-icon-5 {
  width: 6.5rem;
  height: 8.25rem;
  margin-right: 1.8125rem;
}

.step-flow-icon-2,
.step-flow-icon-4 {
  width: 5.125rem;
  height: 6rem;
  margin-top: 0.78125rem;
  margin-right: 2.5rem;
}

.step-flow-text {
  width: 6.75rem;
  height: 1.125rem;
  font-size: 1.125rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 1.5rem;
  margin-top: 0.8125rem;
}

.step-arrow {
  display: inline-block;
  vertical-align: top;
  margin-top: 2.34375rem;
}

.step-arrow-icon {
  width: 2.125rem;
  height: 1.1875rem;
  margin-right: 2.5rem;
}

.recruitment-postscript {
  margin: 0 auto;
  text-align: center;
  width: 41.3125rem;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  white-space: nowrap;
  padding: 1.1875rem 1.5625rem;
  border-radius: 0.625rem;
  border: 0.0625rem dashed rgba(57, 156, 255, 1);
}

/* 联系方式 */

.contact-container {
  height: 23.75rem;
  width: 100%;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 9.375rem;
  background-image: url('../../assets/img/recruitment/contact_way_bg.png');
  background-size: cover;
}

.contact-wrapper {
  padding: 10.3125rem 0 0 57.5rem;
}

@media screen and (max-width: 1300px) {
  .contact-wrapper {
    padding: 10rem 0 0 51.875rem;
  }
}

.contact-way-box {
  display: inline-block;
  padding-top: 0.25rem;
  vertical-align: top;
}

.contact-detail-box {
  display: inline-block;
}

.contact-text {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.125rem;
  margin-bottom: 1rem;
}

.contact-way {
  font-size: 2.125rem;
  font-weight: 500;
  margin-bottom: 1.125rem;
}

.contact-line {
  width: 1.5rem;
  height: 0.4375rem;
}

.contact-detail-box {
  vertical-align: top;
  margin-left: 3.4375rem;
}

.contact {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 0.875rem;
}

.email {
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 0.875rem;
}

.phone {
  font-size: 1.125rem;
  font-weight: 400;
}

/* 招聘岗位 */

.position-warpper {
  height: auto;
}

.line {
  width: 75rem;
  height: 0.0625rem;
  border-top: 0.0625rem solid rgba(57, 156, 255, 1);
  margin: 0 auto;
  opacity: 0.2;
}

.job-list {
  margin: 0 auto;
  text-align: center;
}

.job-card {
  position: relative;
  width: 71.875rem;
  padding-bottom: 3.125rem;
  background: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0 0.375rem 1.375rem 0 rgba(57, 156, 255, 0.25);
  box-shadow: 0 0.375rem 1.375rem 0 rgba(57, 156, 255, 0.25);
  border-radius: 0.625rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 6.875rem;
}

.avatar {
  position: absolute;
  top: -3.125rem;
  left: -3.125rem;
  width: 11.25rem;
  height: 11.25rem;
  background: rgba(255, 255, 255, 1);
  border: 0.25rem solid rgba(57, 156, 255, 1);
  -webkit-box-shadow: 0 0.75rem 1.625rem 0 rgba(57, 156, 255, 0.3);
  box-shadow: 0 0.75rem 1.625rem 0 rgba(57, 156, 255, 0.3);
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  margin-top: 8.75rem;
  width: 100%;
  height: 100%;
  -webkit-transition: margin-top 0.5s;
  transition: margin-top 0.5s;
}

.job-title {
  height: 1.5625rem;
  font-size: 1.75rem;
  font-family: 'BigruixianBoldkGBV10';
  font-weight: 400;
  color: rgba(57, 156, 255, 1);
  padding-top: 4.125rem;
  padding-left: 12.4375rem;
  text-align: left;
}

.job-title-description {
  height: 1rem;
  font-size: 1.125rem;
  font-family: 'BigYoungMediumGB20';
  font-weight: 400;
  color: rgba(128, 192, 255, 1);
  text-align: left;
  line-height: 1.5rem;
  padding-top: 1.375rem;
  padding-left: 12.4375rem;
}

.cut-off-line {
  width: 60.0625rem;
  height: 0.0625rem;
  margin-left: 11.8125rem;
  background: rgba(210, 210, 210, 1);
  margin-top: 2.8125rem;
  opacity: 0.5;
}

.job-desc-list {
  padding-left: 12.5rem;
  text-align: left;
}

.emsoft {
  letter-spacing: 0.078125rem;
}

.job-desc-title {
  font-size: 1.25rem;
  font-family: MicrosoftYaHei;
  font-weight: bold;
  color: rgba(40, 40, 40, 1);
  line-height: 1.875rem;
  padding-top: 3.0625rem;
  margin-bottom: 1.8125rem;
}

.job-desc-item::before {
  display: inline-block;
  content: "";
  color: #ABCDEF;
  height: 0.375rem;
  width: 0.375rem;
  background: rgba(185, 218, 255, 1);
  border-radius: 50%;
  margin-right: 0.625rem;
  vertical-align: middle;
}

.job-desc-item {
  display: block;
  font-size: 1rem;
  color: #959595;
  margin-bottom: 0.75rem;
}