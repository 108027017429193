.home_about_emsoft {
  position: relative;
  height: 50rem;
  background: url("../../assets/img/home/bg_about.png") no-repeat;
  background-size: 100%;
  margin-top: 6.25rem;
}

.home_about_emsoft article {
  position: absolute;
  left: 26.25rem;
}

.home_about_emsoft article div {
  position: relative;
  width: 14.1875rem;
  height: 5rem;
}

.home_about_emsoft article div img {
  width: 2.875rem;
  height: 2.875rem;
  position: absolute;
  top: 1.9375rem;
}

.home_about_emsoft article div h3 {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  position: absolute;
  left: 4.375rem;
  letter-spacing: 0.125rem;
}

.home_about_emsoft article div h2 {
  font-size: 2.125rem;
  font-weight: 400;
  color: #292929;
  position: absolute;
  top: 1.9375rem;
  left: 4.375rem;
}

.home_about_emsoft article .about_emsoft_logo {
  width: 18.75rem;
  height: 11.5rem;
  margin-top: 3.5rem;
}

.home_about_emsoft article .about_partner1_logo {
  width: 10rem;
  margin: 3.125rem 9.375rem 1.75rem 3.125rem;
}

.home_about_emsoft article .about_partner2_logo {
  width: 7.5rem;
  margin: 3.125rem 0 1.75rem 1.875rem;
}

.home_about_emsoft article span {
  color: #888;
  font-size: 1rem;
  line-height: 1.875rem;
  margin-left: 1.25rem;
  margin-right: 4.375rem;
}

.home_about_emsoft article p {
  margin-top: 2.25rem;
  color: #333;
  font-size: 1rem;
  line-height: 1.875rem;
}

.home_description {
  padding: 2.875rem 0 3rem 0;
  background: #edf5fe;
  text-align: center;
}

.home_description section {
  display: inline-block;
  width: 7.5rem;
  text-align: center;
}

.home_description section span {
  font-size: 3rem;
  color: #399CFF;
}

.home_description section .home_description_name {
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #1A1A1A;
  margin-top: 0.625rem;
}

.home_description section .home_description_nameEnglish {
  font-size: 0.75rem;
  color: #808080;
  font-weight: bold;
}

.home_industry_solutions {
  padding: 8.875rem 0 8.4375rem 0;
}

.home_industry_solutions_title {
  width: 31.25rem;
  margin: 0 auto;
  text-align: center;
}

.home_industry_solutions_title h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0.125rem;
}

.home_industry_solutions_title h3 {
  font-size: 2.125rem;
  color: #292929;
  margin: 1.375rem 0 1.25rem 0;
  font-weight: 400;
}

.home_industry_solutions_title img {
  width: 3.75rem;
  margin-bottom: 1.25rem;
}

.home_industry_solutions_title p {
  font-size: 0.875rem;
  color: #999999;
  line-height: 1.4375rem;
}

.home_industry_solutions article {
  width: 77.5rem;
  margin: 0 auto;
  margin-top: 4.1875rem;
}

.home_industry_solutions article section {
  width: 16.875rem;
  margin: 0 1.25rem;
  float: left;
}

.home_industry_solutions article aside {
  height: 11rem;
  text-align: center;
  border-radius: 1rem;
  border-width: 0;
}

.home_industry_solutions article aside p {
  color: #fff;
  font-size: 1.375rem;
  margin-top: 0;
}

.home_industry_solutions article p {
  width: 15rem;
  margin: 0 auto;
  font-size: 1rem;
  color: #888;
  margin-top: 1.5625rem;
}

.home_industry_solutions_internet {
  -webkit-box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(255, 145, 26, 0.25);
  box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(255, 145, 26, 0.25);
  background: url("../../assets/img/home/industry_solutions_1.png");
  background-size: 100%;
}

.home_industry_solutions_internet span {
  font-size: 5.3125rem;
  color: #fff;
  display: inline-block;
  margin: 1.375rem 0 0 0;
}

.home_industry_solutions_internet p {
  margin-top: 1.625rem !important;
}

.home_industry_solutions_AI {
  -webkit-box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(88, 79, 207, 0.25);
  box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(88, 79, 207, 0.25);
  background: url("../../assets/img/home/industry_solutions_2.png");
  background-size: 100%;
}

.home_industry_solutions_AI span {
  font-size: 5.625rem;
  color: #fff;
  display: inline-block;
  margin: 1.5625rem 0 0 0;
}

.home_industry_solutions_AI p {
  margin-top: 1.25rem !important;
}

.home_industry_solutions_data {
  -webkit-box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(61, 138, 251, 0.25);
  box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(61, 138, 251, 0.25);
  background: url("../../assets/img/home/industry_solutions_3.png");
  background-size: 100%;
}

.home_industry_solutions_data span {
  font-size: 5.625rem;
  color: #fff;
  display: inline-block;
  margin: 1.5rem 0 0 0;
}

.home_industry_solutions_data p {
  margin-top: 1.25rem !important;
}

.home_industry_solutions_cloud {
  -webkit-box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(255, 100, 94, 0.25);
  box-shadow: 0 0.375rem 1.5625rem 0.1875rem rgba(255, 100, 94, 0.25);
  background: url("../../assets/img/home/industry_solutions_4.png");
  background-size: 100%;
}

.home_industry_solutions_cloud span {
  font-size: 4.375rem;
  width: auto;
  color: #fff;
  display: inline-block;
  margin: 2rem 0 0 0;
}

.home_industry_solutions_cloud p {
  margin-top: 2rem !important;
}

.home_product_header {
  text-align: center;
  background: #edf5fe;
  padding: 4.375rem 0 3.125rem 0;
}

.home_product_header h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0.125rem;
}

.home_product_header h3 {
  font-size: 2.125rem;
  font-weight: 400;
  color: #292929;
  margin: 0.9375rem 0 0.625rem 0;
}

.home_product_header img {
  width: 3.75rem;
}

.home_product_header p {
  color: #999999;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 1.25rem;
}

.home_product_content {
  position: relative;
}

.home_product_content .home_product_menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 3.125rem;
  left: 50%;
  margin-left: -47.5rem;
}

.home_product_content .home_product_menu ul {
  position: relative;
  z-index: 11;
}

.home_product_content .home_product_menu ul li {
  display: inline-block;
  height: 3.375rem;
  line-height: 3.375rem;
  border-radius: 1.6875rem;
  border: 0.0625rem solid #fff;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0 1.25rem;
  margin: 0 1rem;
  cursor: pointer;
}

.home_product_content .home_product_menu ul .selected {
  background: #fff;
  color: #399CFF;
  font-weight: 700;
}

.home_product_content .home_product_menu ul li:first-child {
  margin-left: 0;
}

.home_product_content .home_product_menu ul li:last-child {
  margin-right: 0;
}

.home_product_content .home_product_list ul li {
  position: relative;
  z-index: 1;
}

.home_product_content .home_product_list ul li img {
  width: 100%;
}

.home_product_content .home_product_list ul li .home_product_content {
  position: absolute;
  top: 5rem;
  left: 50%;
  margin-left: -41.25rem;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message {
  width: 82.5rem;
  margin: 0 auto 0 auto;
  padding: 6.25rem 0 0 0;
}

@media screen and (max-width: 1400px) {
  .home_product_content .home_product_list ul li .home_product_content .home_product_message {
    padding: 5.3125rem 0 0 0;
  }
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_img {
  width: 38.125rem;
  height: 25.625rem;
  line-height: 25.625rem;
  text-align: center;
  display: inline-block;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_intro {
  width: 34.375rem;
  display: inline-block;
  position: relative;
  top: 5.625rem;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_intro h2 {
  font-size: 2.125rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 2.5rem;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_intro p {
  height: 6.25rem;
  font-size: 1.125rem;
  font-weight: 400;
  color: #fff;
  opacity: 0.7;
  letter-spacing: 0.125rem;
  line-height: 1.875rem;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_intro a {
  display: block;
  background: #5e68d8;
  color: #fff;
  width: 7.5rem;
  height: 2.875rem;
  line-height: 2.875rem;
  text-align: center;
  border-radius: 1.4375rem;
  font-size: 1rem;
  margin-top: 1.875rem;
  opacity: 0.6;
  cursor: pointer;
  border: 0.0625rem solid #4048ce;
}

.home_product_content .home_product_list ul li .home_product_content .home_product_message .product_message_intro a:hover {
  background: #5d7cff;
  cursor: pointer;
  opacity: 0.9;
}

.home_product_content .home_product_list ul .home_product_flow {
  width: 75rem;
  margin: 0.625rem auto 0 auto;
  padding-top: 1.875rem;
  border-top: 0.0625rem dotted #fff;
  text-align: center;
}

@media screen and (min-width: 1330px) and (max-width: 1400px) {
  .home_product_content .home_product_list ul .home_product_flow {
    margin: 0 auto;
    padding-top: 1.25rem;
  }
}

@media screen and (max-width: 1330px) {
  .home_product_content .home_product_list ul .home_product_flow {
    margin: 0 auto;
    padding-top: 1rem;
  }
}

.home_product_content .home_product_list ul .home_product_flow ol li {
  display: inline-block;
  width: 7.5rem;
  margin: 0 3.75rem;
  text-align: center;
  height: auto;
}

.home_product_content .home_product_list ul .home_product_flow ol li img {
  width: 5rem;
}

.home_product_content .home_product_list ul .home_product_flow ol li p {
  color: #fff;
  font-size: 1rem;
  margin-top: 1.25rem;
}

.home_product_content .home_product_list .zhuwei .product_message_img img {
  width: 26.25rem;
}

.home_product_content .home_product_list .jiaoyu .product_message_img img {
  width: 19.25rem;
}

.home_product_content .home_product_list .xiaoyuan .product_message_img img {
  width: 28.125rem;
}

.home_product_content .home_product_list .hulian .product_message_img img {
  width: 27.5rem;
}

.home_product_content .home_product_list .zhaobiao .product_message_img img {
  width: 28rem;
}

.home_product_content .home_product_list .fuxing .product_message_img img {
  width: 26rem;
}

.home_product_footer {
  padding: 1.875rem 0 4.375rem 0;
  background: #edf5fe;
  text-align: center;
}

.home_product_footer img {
  width: 1.875rem;
}

.home_product_footer a {
  display: block;
  width: 17.5rem;
  height: 3.375rem;
  border: 0.0625rem solid #399cff;
  border-radius: 1.6875rem;
  margin: 1.25rem auto 0 auto;
  color: #399cff;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 3.375rem;
  cursor: pointer;
}

.home_product_footer a:hover {
  -webkit-box-shadow: 0.25rem 0.375rem 1.25rem 0.375rem rgba(125, 177, 249, 0.15);
  box-shadow: 0.25rem 0.375rem 1.25rem 0.375rem rgba(125, 177, 249, 0.15);
}

.home_businese_header {
  text-align: center;
  padding: 4.375rem 0 3.125rem 0;
}

.home_businese_header h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0.125rem;
}

.home_businese_header h3 {
  font-size: 2.125rem;
  font-weight: 400;
  color: #292929;
  margin: 0.9375rem 0 0.625rem 0;
}

.home_businese_header img {
  width: 3.75rem;
}

.home_businese_header p {
  color: #999999;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 1.25rem;
}

.home_businese_content {
  width: 82.5rem;
  height: 31.875rem;
  margin: 0 auto;
  background: url("../../assets/img/home/china_map.png") no-repeat;
  background-size: 100%;
  position: relative;
}

.home_businese_content ul li {
  display: block;
  position: absolute;
  text-align: center;
}

.home_businese_content ul li div {
  position: relative;
}

.home_businese_content ul li div span {
  display: block;
  text-align: center;
  padding: 0 0.625rem;
  background: #d0e8ff;
  border-radius: 0.25rem;
  line-height: 1.875rem;
  color: #0e60db;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.375rem;
}

.home_businese_content ul li div img {
  width: 1.75rem;
  position: relative;
  z-index: 10;
  -webkit-animation: jump 1.4s ease-in infinite;
  animation: jump 1.4s ease-in infinite;
}

.home_businese_content ul li div i {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  background: #399cff;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-transform: rotateX(55deg);
  transform: rotateX(55deg);
  position: absolute;
  top: 4rem;
  left: 50%;
  margin-left: -0.3125rem;
  z-index: 1;
  opacity: 0.5;
  -webkit-animation: pulsate 1.4s linear infinite;
  animation: pulsate 1.4s linear infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-box-shadow: 0 0 0.0625rem 0.0625rem #399cff;
  box-shadow: 0 0 0.0625rem 0.0625rem #399cff;
}

.home_businese_content ul li div::after {
  content: "";
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  top: 2.8125rem;
  left: 50%;
  margin-left: -1.5625rem;
  background: #b0d6f4;
  position: absolute;
  -webkit-transform: rotateX(55deg);
  transform: rotateX(55deg);
  border-radius: 50%;
  opacity: 0.7;
  z-index: 0;
  -webkit-animation: pulsate-outer 1.4s linear infinite;
  animation: pulsate-outer 1.4s linear infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-box-shadow: 0 0 0.0625rem 0.0625rem #b0d6f4;
  box-shadow: 0 0 0.0625rem 0.0625rem #b0d6f4;
}

.home_businese_content .wulumuqi {
  top: 0.375rem;
  left: 22.4375rem;
}

.home_businese_content .taiyuan {
  top: 3.8125rem;
  left: 52.0625rem;
}

.home_businese_content .beijing {
  top: 2rem;
  left: 56.75rem;
}

.home_businese_content .changchun {
  top: -0.4375rem;
  left: 63.625rem;
}

.home_businese_content .wuhan {
  top: 9.3125rem;
  left: 59.125rem;
}

.home_businese_content .changsha {
  top: 12.4375rem;
  left: 56.25rem;
}

.home_businese_content .shanghai {
  top: 8.6875rem;
  left: 69.875rem;
}

.home_businese_content .fuzhou {
  top: 14.25rem;
  left: 71.6875rem;
}

.home_businese_footer {
  width: 67.5rem;
  margin: 0 auto;
  padding: 0 6.25rem;
  margin-bottom: 5rem;
}

.home_businese_footer span {
  float: left;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.875rem;
  color: #43A1ff;
  margin-top: 1rem;
  margin-right: 0.5625rem;
}

.home_businese_footer ul li {
  float: left;
  width: 1em;
  color: #808080;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
  margin: 0 0.375rem;
}

.home_businese_footer ul li::before {
  content: ' ';
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #399CFF;
  margin: 0 auto;
  margin-bottom: 0.625rem;
}

.home_businese_footer i {
  width: 0.0625rem;
  height: 2.5rem;
  background: #e6e6e6;
  display: block;
  float: left;
  margin: 0.4375rem 1.375rem;
}

.home_qulification {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home_qulification_left {
  margin-right: 1.25rem;
}

.home_qulification_left ul li {
  font-size: 1.25rem;
  line-height: 3.75rem;
  font-weight: 400;
  margin: 0 0.375rem;
}

.home_qulification_left ul li img {
  width: 1rem;
  height: 1rem;
}

.home_qulification_right1 {
  margin-left: 0.5rem;
  width: 14.375rem;
  height: 21rem;
}

.home_qulification_right2 {
  margin-left: 0.5rem;
  width: 16rem;
  height: 22.75rem;
}

.home_qulification_right3 {
  padding-top: 0.625rem;
  margin-left: 0.5rem;
  width: 15.25rem;
  height: 21.875rem;
}

.home_chooce_us {
  height: 49.25rem;
  background: url("../../assets/img/home/chooce_us_bg.png") center no-repeat;
  background-size: 100%;
  overflow: hidden;
}

.home_chooce_us_header {
  padding-top: 7.5rem;
  text-align: center;
}

.home_chooce_us_header h2 {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

.home_chooce_us_header h3 {
  color: #fff;
  font-size: 2.125rem;
  font-weight: 400;
  margin: 0.9375rem 0 0.9375rem 0;
}

.home_chooce_us_header img {
  width: 3.75rem;
}

.home_chooce_us_header p {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 1.25rem;
}

.home_chooce_us_content {
  width: 102.5rem;
  margin: 0 auto;
  position: relative;
  left: 50%;
  margin-left: -51.25rem;
  margin-top: 4.375rem;
  overflow: hidden;
}

.home_chooce_us_content article {
  position: relative;
  padding-bottom: 1.25rem;
  height: 25rem;
}

.home_chooce_us_content article section {
  width: 18rem;
  margin: 0 1.25rem;
  overflow: hidden;
  border-radius: 1rem;
  -webkit-transition-duration: 1000ms;
  transition-duration: 1000ms;
  position: absolute;
  top: 1.875rem;
  -webkit-box-shadow: 0 0.375rem 1rem 0.125rem rgba(102, 120, 230, 0.25);
  box-shadow: 0 0.375rem 1rem 0.125rem rgba(102, 120, 230, 0.25);
}

.home_chooce_us_content article section .card_header {
  position: relative;
}

.home_chooce_us_content article section .card_header img {
  width: 18rem;
}

.home_chooce_us_content article section .card_header p {
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -1.5rem;
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
}

.home_chooce_us_content article section .card_header p span {
  font-size: 0.75rem;
  font-weight: 400;
}

.home_chooce_us_content article section .card_centent {
  padding: 2.25rem 2rem;
  background: #fff;
}

.home_chooce_us_content article section .card_centent .card_title {
  text-align: center;
  display: none;
}

.home_chooce_us_content article section .card_centent .card_title i {
  display: inline-block;
  width: 1.625rem;
  height: 0.1875rem;
  background: #399CFF;
  position: relative;
  top: -1.25rem;
}

.home_chooce_us_content article section .card_centent .card_title h3 {
  display: inline-block;
  font-size: 1.375rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #399CFF;
  margin: 0 0.9375rem;
  margin-bottom: 0.625rem;
}

.home_chooce_us_content article section .card_centent .card_title h3 span {
  font-size: 0.75rem;
}

.home_chooce_us_content article section .card_centent p {
  color: #808080;
}

.home_chooce_us_content article .selected_card {
  top: 0;
}

.home_chooce_us_content article .selected_card .card_header p {
  display: none;
}

.home_chooce_us_content article .selected_card .card_centent .card_title {
  display: block;
}

.home_chooce_us_content article .card_list_1 {
  position: absolute;
  left: 1.25rem;
  z-index: 1;
  -webkit-transform: scale(0.85, 0.85);
  transform: scale(0.85, 0.85);
  opacity: 0.9;
}

.home_chooce_us_content article .card_list_2 {
  position: absolute;
  left: 21.75rem;
  z-index: 6;
  opacity: 0.9;
}

.home_chooce_us_content article .card_list_3 {
  position: absolute;
  left: 42.25rem;
  z-index: 10;
}

.home_chooce_us_content article .card_list_4 {
  position: absolute;
  left: 62.75rem;
  z-index: 6;
  opacity: 0.9;
}

.home_chooce_us_content article .card_list_5 {
  position: absolute;
  left: 83.25rem;
  z-index: 2;
  -webkit-transform: scale(0.85, 0.85);
  transform: scale(0.85, 0.85);
  opacity: 0.9;
}

.home_chooce_us_content article .card_list_6 {
  position: absolute;
  left: 42.25rem;
  z-index: 0;
  opacity: 0.9;
  -webkit-transform: scale(0.85, 0.85);
  transform: scale(0.85, 0.85);
}

.home_partners {
  padding: 5rem 0 5.3125rem 0;
}

.home_partners_title {
  width: 75rem;
  margin: 0 auto;
  text-align: center;
}

.home_partners_title h2 {
  font-size: 1rem;
  color: #999999;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

.home_partners_title h3 {
  font-size: 2.125rem;
  color: #292929;
  margin: 0.9375rem 0 0.625rem 0;
  font-weight: 400;
}

.home_partners_title img {
  width: 3.75rem;
}

.home_partners_title span {
  display: block;
  width: 75rem;
  height: 0.0625rem;
  background: #cae9f7;
}

.home_partners article {
  width: 80rem;
  margin: 0 auto;
}

.home_partners article section {
  float: left;
  width: 16.875rem;
  margin: 1.25rem 1.4375rem 0 1.4375rem;
  text-align: center;
}

.home_partners article section img {
  width: 13.125rem;
  margin: 2.5rem 0;
}

.home_partners article section p {
  color: #666;
  margin-top: 0.9375rem;
}

.home_contact_us {
  height: 47.5rem;
  background: url("../../assets/img/home/map.png") 0 10.625rem no-repeat;
  background-size: 100%;
}

.home_contact_us address {
  width: 52.5rem;
  height: 48.125rem;
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  background: url("../../assets//img//home/envelope.png") no-repeat;
  background-size: 100%;
}

.home_contact_us address h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  margin-top: 5rem;
  margin-bottom: 1.25rem;
  display: inline-block;
  letter-spacing: 0.125rem;
}

.home_contact_us address h3 {
  font-size: 2.125rem;
  font-weight: 400;
  color: #292929;
}

.home_contact_us address img {
  display: inline-block;
}

@-webkit-keyframes jump {
  0% {
    top: 0;
  }

  50% {
    top: 0.1875rem;
  }

  100% {
    top: 0;
  }
}

@keyframes jump {
  0% {
    top: 0;
  }

  50% {
    top: 0.1875rem;
  }

  100% {
    top: 0;
  }
}

@-webkit-keyframes pulsate {
  from {
    width: 0.625rem;
    height: 0.625rem;
    opacity: 0.7;
    top: 4rem;
    margin-left: -0.3125rem;
  }

  to {
    width: 3.75rem;
    height: 3.75rem;
    opacity: 0;
    top: 2.5rem;
    margin-left: -1.875rem;
  }
}

@keyframes pulsate {
  from {
    width: 0.625rem;
    height: 0.625rem;
    opacity: 0.7;
    top: 4rem;
    margin-left: -0.3125rem;
  }

  to {
    width: 3.75rem;
    height: 3.75rem;
    opacity: 0;
    top: 2.5rem;
    margin-left: -1.875rem;
  }
}

@-webkit-keyframes pulsate-outer {
  from {
    width: 3.125rem;
    height: 3.125rem;
    opacity: 0.7;
    top: 2.8125rem;
    margin-left: -1.5625rem;
  }

  to {
    width: 6.25rem;
    height: 6.25rem;
    opacity: 0;
    top: 1.25rem;
    margin-left: -3.125rem;
  }
}

@keyframes pulsate-outer {
  from {
    width: 3.125rem;
    height: 3.125rem;
    opacity: 0.7;
    top: 2.8125rem;
    margin-left: -1.5625rem;
  }

  to {
    width: 6.25rem;
    height: 6.25rem;
    opacity: 0;
    top: 1.25rem;
    margin-left: -3.125rem;
  }
}