@charset "UTF-8";

.item-block:hover .item-block-normal-sub-title,
.item-block:hover .item-block-normal-title,
.item-block-normal-title,
.item-block-normal-sub-title {
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

.menu,
.menu-hidden {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

/********** 页面头部菜单样式 **********/

.menu {
  background: #FFF;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 4.125rem;
  z-index: 99999;
  opacity: 1;
  -webkit-box-shadow: 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.2);
  box-shadow: 0.0625rem 0.0625rem 0.625rem rgba(0, 0, 0, 0.2);
}

.menu-wrapper {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 58.25rem;
  padding: 0;
  margin: 0 auto;
  height: 4.125rem;
}

.menu-wrapper .follow {
  border-bottom: 0.25rem solid #399CFF;
  border-radius: 0.125rem;
  position: absolute;
  z-index: 9999;
  bottom: 0;
  width: 1.875rem;
  opacity: 1;
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
}

.menu-wrapper .follow-0 {
  left: 3.8125rem;
}

.menu-wrapper .follow-1 {
  left: 13.3125rem;
}

.menu-wrapper .follow-2 {
  left: 26.8125rem;
}

.menu-wrapper .follow-3 {
  left: 40.3125rem;
}

.menu-wrapper .follow-4 {
  left: 49.8125rem;
}

.menu-hidden {
  opacity: 0 !important;
  top: -4.125rem !important;
}

.item-block {
  height: 4.125rem;
  padding: 0 2.5rem;
  overflow: hidden;
  position: relative;
}

.item-block:hover .item-block-normal-sub-title {
  color: #8DC6FF;
}

.item-block:hover .item-block-normal-title {
  color: #399CFF;
}

.item-block-current {
  position: relative;
}

.item-block-current:after {
  content: '';
  display: block;
  width: 1.875rem;
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-0.9375rem);
  transform: translateX(-0.9375rem);
  border-bottom: 0.25rem solid #399CFF;
  border-radius: 0.125rem;
  -webkit-animation: jump 1s ease-in;
  animation: jump 1s ease-in;
}

.item-block-current .item-block-normal-sub-title {
  color: #8DC6FF;
}

.item-block-current .item-block-normal-title {
  color: #399CFF;
}

.item-block-normal {
  padding-top: 1.25rem;
  text-align: center;
  width: 4.5rem;
}

.item-block-normal-title {
  font-size: 1.125rem;
  color: #282828;
  line-height: 1;
}

.item-block-normal-sub-title {
  padding: 0.25rem 0 0.875rem 0;
  font-size: 0.75rem;
  color: #808080;
  line-height: 1;
}

.item-block-home {
  text-align: center;
  line-height: 3.5rem;
}

.item-block-home > img {
  width: 12.5rem;
  height: auto;
}

@-webkit-keyframes jump {
  0% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }

  25% {
    width: 3.125rem;
    -webkit-transform: translateX(-1.5625rem);
    transform: translateX(-1.5625rem);
  }

  50% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }

  75% {
    width: 2.5rem;
    -webkit-transform: translateX(-1.25rem);
    transform: translateX(-1.25rem);
  }

  100% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }
}

@keyframes jump {
  0% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }

  25% {
    width: 3.125rem;
    -webkit-transform: translateX(-1.5625rem);
    transform: translateX(-1.5625rem);
  }

  50% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }

  75% {
    width: 2.5rem;
    -webkit-transform: translateX(-1.25rem);
    transform: translateX(-1.25rem);
  }

  100% {
    width: 1.875rem;
    -webkit-transform: translateX(-0.9375rem);
    transform: translateX(-0.9375rem);
  }
}

/********** 页面头部轮播样式 **********/

.swiper {
  padding-top: 4.125rem;
}

/**********  页面底部 - 上半部分图标列表 **********/

.footer {
  line-height: 1.25rem;
}

.icon-list {
  height: 12.5rem;
  background: #EDF5FE;
  overflow: hidden;
}

.icon-list-wrapper {
  position: relative;
  width: 81.25rem;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.icon-list-body {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: -5rem;
  top: 0;
  width: 91.25rem;
  height: 100%;
  padding: 2.875rem 0;
}

.icon-list-item {
  width: 20%;
  display: inline-block;
  min-width: 9.375rem;
  text-align: center;
}

.icon-list-item-img {
  height: 2.75rem;
}

.icon-list-item-img img {
  height: 100%;
  width: auto;
}

.icon-list-item-title {
  padding: 0.9375rem 0 0.625rem 0;
  color: #333;
  font-size: 1.25rem;
}

.icon-list-item-sub-title {
  color: #808080;
  font-size: 0.875rem;
}

/**********  页面底部 - 下半部分页脚信息 **********/

.base-info,
.about-us,
.solution,
.follow-us {
  float: left;
  height: 100%;
}

.base-info-sub-title::after,
.base-info-sub-title::before {
  content: '';
  position: absolute;
  top: 1.375rem;
  border-top: 1px solid #fff;
  width: 2.125rem;
}

.footer-info {
  overflow: hidden;
}

.footer-info-top {
  position: relative;
}

.footer-info-top-bg img {
  width: 100%;
  height: auto;
}

.footer-info-top-filing {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2.875rem;
  z-index: 9999;
  text-align: center;
  line-height: 2.875rem;
  background: rgba(38, 64, 191, 0.75);
  font-size: 0.875rem;
  color: #fff;
}

.footer-info-top-link {
  display: inline-block;
  color: #fff;
}

.footer-info-top-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.footer-info-top-wrapper-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 69.625rem;
  padding-top: 3.625rem;
  margin: 0 auto;
}

.footer-item-header {
  padding-top: 2.625rem;
}

.footer-item-header-title {
  position: relative;
  padding-top: 0.875rem;
  padding-bottom: 3.125rem;
  color: #fff;
  font-size: 1.25rem;
}

.footer-item-header-title::after {
  content: '';
  border-top: 0.1875rem solid #fff;
  border-radius: 0.125rem;
  width: 1.875rem;
  position: absolute;
  left: 0;
  top: 0;
}

.footer-item-ul {
  color: #fff;
  font-size: 0.875rem;
}

.footer-item-ul-li {
  text-indent: 1em;
  padding-bottom: 0.875rem;
  position: relative;
}

.footer-item-ul-li:hover {
  text-indent: 1em;
}

.footer-item-ul-li:hover::before {
  content: '> ';
  position: absolute;
  left: -1em;
}

.base-info {
  width: 25rem;
  color: #fff;
}

.base-info-img {
  width: 16.875rem;
}

.base-info-img img {
  width: 100%;
  height: auto;
}

.base-info-sub-title {
  position: relative;
  display: block;
  width: 16.875rem;
  padding-top: 1rem;
  padding-bottom: 3.125rem;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
}

.base-info-sub-title::after {
  left: 0;
}

.base-info-sub-title::before {
  right: 0;
}

.base-info-phone-label {
  font-size: 0.875rem;
}

.base-info-phone-num {
  font-size: 1.5rem;
  padding: 0.625rem 0;
}

.base-info-phone-tel {
  font-size: 1.5rem;
  padding: 0.625rem 0;
}

.base-info-email-address {
  font-size: 0.875rem;
}

.about-us {
  width: 15.5rem;
}

.solution {
  width: 18.25rem;
}

.follow-us {
  width: 10.625rem;
}

.follow-us-qr {
  width: 10.625rem;
}

.follow-us-qr img {
  width: 100%;
  height: auto;
}

/********  侧边图标  ********/

.side-block {
  position: fixed;
  z-index: 9999;
  right: 1.5rem;
  bottom: 6.25rem;
}

.side-block-list {
  width: 3.75rem;
  border-radius: 2rem;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 0.25rem 1.125rem 0.0625rem rgba(73, 134, 234, 0.2);
  box-shadow: 0 0.25rem 1.125rem 0.0625rem rgba(73, 134, 234, 0.2);
  border: 0.0625rem solid #F0F0F0;
}

.side-block-list > div {
  height: 3.75rem;
}

.side-block-list-weixin {
  border-bottom: 0.0625rem solid #F0F0F0;
}

.side-block-list-weixin span {
  font-size: 2.1875rem;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 0.625rem;
  color: #8e8e8e;
}

.side-block-list-weixin:hover span {
  color: #3192ff;
}

.side-block-list-arrow span {
  font-size: 2.375rem;
  display: block;
  margin: 0 auto;
  width: auto;
  position: relative;
  top: 0.625rem;
  color: #8e8e8e;
}

.side-block-list-arrow:hover span {
  color: #3192ff;
}

.side-block-list-home span {
  font-size: 2.1875rem;
  display: block;
  margin: 0 auto;
  position: relative;
  top: 0.625rem;
  color: #8e8e8e;
}

.side-block-list-home:hover span {
  color: #3192ff;
}

.side-block-qr {
  position: absolute;
  top: 2.25rem;
  right: 3.75rem;
}

.side-block-qr-wrapper {
  width: 6.875rem;
  height: 6.875rem;
  padding-left: 1.25rem;
  padding-right: 1.875rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-image: url("../assets/img/layout/side-shadow.png");
  background-position: 0.125rem 0.3125rem;
  background-repeat: no-repeat;
  background-size: 9.5rem;
}

.side-block-qr-wrapper img {
  width: 100%;
  height: auto;
}

/* 进度条 */

.progress-bar {
  position: fixed;
  z-index: 999999;
  border-radius: 0.125rem;
  border-top: 0.1875rem solid #399CFF;
  -webkit-animation: progress-bar 0.8s ease-in;
  animation: progress-bar 0.8s ease-in;
}

@-webkit-keyframes progress-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}