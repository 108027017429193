@charset "UTF-8";

/**********通用头部 **********/

.title-block {
  text-align: center;
}

.title-block-sub-title {
  color: #999;
  font-size: 1rem;
  letter-spacing: 0.1875rem;
}

.title-block-title {
  color: #292929;
  font-size: 2.125rem;
  line-height: 1;
  padding-top: 1.5625rem;
  padding-bottom: 1.25rem;
}

.title-block-icon {
  max-width: 75rem;
  width: 100%;
  margin: 0 auto;
  border-bottom: 0.0625rem solid rgba(57, 156, 255, 0.2);
}

.title-block-icon > img {
  width: 3.75rem;
  height: auto;
}

.title-block-content {
  padding-top: 1.25rem;
  font-size: 0.875rem;
  color: #999;
  text-align: center;
}

/********** 业务列表 **********/

.business {
  padding-top: 6.1875rem;
}

.business-list {
  padding-top: 3.875rem;
  width: 75rem;
  margin: 0 auto;
}

.business-more {
  width: 18.75rem;
  color: #999;
  border: 0.0625rem solid #E5E5E5;
  margin: 0 auto;
  margin-top: 3.75rem;
  border-radius: 1.4375rem;
  line-height: 1;
  font-size: 1.125rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.6875rem;
  text-align: center;
}

.business-item {
  text-align: center;
  position: relative;
  width: 14rem;
  height: 22.5rem;
  padding: 0 2.375rem;
}

.business-item img {
  width: 11rem;
  height: auto;
}

.business-item::after {
  content: '';
  display: block;
  width: 3.125rem;
  height: 0.25rem;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 1.5625rem);
  bottom: 0;
  background: #399CFF;
  border-radius: 0.125rem;
}

.business-item-title {
  font-size: 1.5rem;
  color: #333;
  line-height: 2.5;
  padding-top: 0.625rem;
}

.business-item-content {
  text-align: left;
  color: #666;
  font-size: 1rem;
}

/******** 服务介绍 ********/

.service-introduction {
  padding-top: 6.25rem;
  padding-bottom: 5.625rem;
  overflow: hidden;
}

.service-icon-list {
  width: 62.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1.25rem;
  margin: 0 auto;
  padding-top: 6rem;
}

.service-icon-list-item {
  width: 7.5rem;
  text-align: center;
  padding: 0 6.25rem;
}

.service-icon-list-item > img {
  width: 3.125rem;
  height: auto;
}

.service-icon-list-item > p {
  color: #666;
  font-size: 1.125rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.service-row-yellow {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 1.25rem;
  width: 62.5rem;
  border: 0.0625rem dashed rgba(254, 185, 20, 0.6);
  border-radius: 0.5rem;
  height: 8.125rem;
}

.service-row-yellow .service-block-item {
  background: #FEB914;
  -webkit-box-shadow: 0 0.3125rem 0.75rem 0 rgba(254, 185, 20, 0.35);
  box-shadow: 0 0.3125rem 0.75rem 0 rgba(254, 185, 20, 0.35);
}

.service-row-yellow .service-row-yellow-title {
  position: relative;
  vertical-align: middle;
  width: 8.75rem;
  height: 100%;
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  line-height: 8.75rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  color: #FEB914;
}

.service-row-yellow .service-row-yellow-title::after {
  content: '';
  position: absolute;
  top: calc(50% - 2.1875rem);
  right: 0;
  height: 4.375rem;
  border-left: 0.0625rem solid #FEB914;
}

.service-row-yellow .service-row-yellow-body {
  width: 53.125rem;
  margin: 0 auto;
  font-size: 1rem;
}

.service-row-red {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 1.25rem;
  width: 62.5rem;
  border: 0.0625rem dashed rgba(255, 88, 100, 0.6);
  border-radius: 0.5rem;
  height: 8.125rem;
}

.service-row-red .service-block-item {
  background: #FF5864;
  -webkit-box-shadow: 0 0.3125rem 0.75rem 0 rgba(255, 88, 100, 0.35);
  box-shadow: 0 0.3125rem 0.75rem 0 rgba(255, 88, 100, 0.35);
}

.service-row-red .service-row-red-title {
  position: relative;
  vertical-align: middle;
  width: 8.75rem;
  height: 100%;
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  line-height: 8.75rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  color: #FF5864;
}

.service-row-red .service-row-red-title::after {
  content: '';
  position: absolute;
  top: calc(50% - 2.1875rem);
  right: 0;
  height: 4.375rem;
  border-left: 0.0625rem solid #FF5864;
}

.service-row-red .service-row-red-body {
  width: 53.125rem;
  margin: 0 auto;
  font-size: 1rem;
}

.service-row-purple {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 1.25rem;
  width: 62.5rem;
  border: 0.0625rem dashed rgba(121, 84, 238, 0.6);
  border-radius: 0.5rem;
  height: 7.5rem;
}

.service-row-purple .service-block-item {
  background: #7954EE;
  -webkit-box-shadow: 0 0.3125rem 0.75rem 0 rgba(121, 84, 238, 0.35);
  box-shadow: 0 0.3125rem 0.75rem 0 rgba(121, 84, 238, 0.35);
}

.service-row-purple .service-row-purple-title {
  position: relative;
  vertical-align: middle;
  width: 8.75rem;
  height: 100%;
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  line-height: 8.75rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  color: #7954EE;
}

.service-row-purple .service-row-purple-title::after {
  content: '';
  position: absolute;
  top: calc(50% - 2.1875rem);
  right: 0;
  height: 4.375rem;
  border-left: 0.0625rem solid #7954EE;
}

.service-row-purple .service-row-purple-body {
  width: 53.125rem;
  margin: 0 auto;
  font-size: 1rem;
}

.service-row-blue {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 1.25rem;
  width: 62.5rem;
  border: 0.0625rem dashed rgba(57, 156, 255, 0.6);
  border-radius: 0.5rem;
  height: 7.5rem;
}

.service-row-blue .service-block-item {
  background: #399CFF;
  -webkit-box-shadow: 0 0.3125rem 0.75rem 0 rgba(57, 156, 255, 0.35);
  box-shadow: 0 0.3125rem 0.75rem 0 rgba(57, 156, 255, 0.35);
}

.service-row-blue .service-row-blue-title {
  position: relative;
  vertical-align: middle;
  width: 8.75rem;
  height: 100%;
  -webkit-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  line-height: 8.75rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  color: #399CFF;
}

.service-row-blue .service-row-blue-title::after {
  content: '';
  position: absolute;
  top: calc(50% - 2.1875rem);
  right: 0;
  height: 4.375rem;
  border-left: 0.0625rem solid #399CFF;
}

.service-row-blue .service-row-blue-body {
  width: 53.125rem;
  margin: 0 auto;
  font-size: 1rem;
}

.service-block-five-item > div,
.service-block-four-item > div,
.service-block-one-item > div {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.5rem;
}

.service-block-five-item {
  padding-top: 0.375rem;
  padding-left: 1.875rem;
}

.service-block-five-item > div {
  line-height: 2.5rem;
  width: 7.5rem;
  margin-left: 1.875rem;
  margin-top: 0.75rem;
}

.service-block-four-item {
  padding-top: 1.875rem;
  padding-left: 1.875rem;
}

.service-block-four-item > div {
  line-height: 3.75rem;
  width: 9.875rem;
  margin-left: 1.875rem;
}

.service-block-one-item {
  margin: 1.625rem auto;
  width: 31.25rem;
}

.service-block-one-item > div {
  line-height: 4.25rem;
  width: 31.25rem;
}

/********** 技术能力 **********/

.technical {
  background-image: url("../../assets/img/about/technical_bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6.25rem 0;
  height: 60rem;
  overflow: hidden;
}

.technical-wrapper {
  width: 75.375rem;
  height: 46.875rem;
  margin: 0 auto;
  background-image: url("../../assets/img/about/technical-bg.png");
  background-size: 37.875rem;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.technical-tabbar {
  width: 100%;
  height: 27.5rem;
  margin: 0 auto;
  background-image: url("../../assets/img/about/bn_about.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.technical-list {
  padding-top: 2.5rem;
}

.technical-list-item {
  line-height: 1;
  padding-top: 6.125rem;
}

.technical-list-title {
  position: relative;
  font-size: 1.5rem;
  height: 1.5rem;
  line-height: 1;
  color: #fff;
}

.technical-list-title::before {
  content: '';
  display: block;
  position: absolute;
  left: -1.5rem;
  top: 0.375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #fff;
}

.technical-list-sub-title {
  font-size: 1rem;
  color: #fff;
  opacity: 0.8;
  padding-top: 1.25rem;
}

/********** 产品发展 **********/

.development {
  overflow: hidden;
  padding: 7.5rem 0;
  margin: 0 auto;
}

.development-bg {
  width: 70rem;
  margin: 0 auto;
  height: 1.125rem;
  border-radius: 0.125rem;
  background: url("../../assets/img/about/time_line_bg.png") bottom center no-repeat;
  background: -webkit-gradient(linear, left top, right top, from(#232FD2), color-stop(#3FB4FF), to(#C7EEFF));
  background: linear-gradient(to right, #232FD2, #3FB4FF, #C7EEFF);
}

.timeline {
  width: 70rem;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 8.75rem;
  padding-left: 0.9375rem;
}

.timeline-time {
  position: absolute;
  width: 3.75rem;
  left: -1.875rem;
  top: -3.5rem;
  font-size: 1.625rem;
  text-align: center;
  color: #399CFF;
}

.timeline-item {
  position: relative;
  width: 22.875rem;
  display: inline-block;
  vertical-align: bottom;
  border-left: 0.0625rem solid #95C7FF;
}

.timeline-item:after {
  content: '';
  left: -0.625rem;
  top: -1.125rem;
  position: absolute;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  border: 0.25rem solid #399CFF;
  border-radius: 50%;
}

.timeline-item:nth-child(1) {
  height: 16.875rem;
}

.timeline-item:nth-child(2) {
  height: 21.75rem;
}

.timeline-item:nth-child(3) {
  height: 27rem;
}

.timeline-title {
  font-weight: normal;
  font-size: 1.625rem;
  color: #292929;
  line-height: 1;
  padding-left: 1.25rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.timeline-content {
  font-size: 1rem;
  color: #808080;
  padding-left: 1.25rem;
  width: 19.375rem;
  line-height: 1.8;
}

/********** 专业团队 **********/

.team-list-indication-item,
.team-list-indication-current {
  width: 0.375rem;
  height: 0.875rem;
  margin-top: 0.75rem;
  border-radius: 0.1875rem;
  background: #399CFF;
  cursor: pointer;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}

.team {
  background: #EDF5FE;
  padding: 6.75rem 0;
}

.team-list {
  position: relative;
  width: 75rem;
  margin: 0 auto;
  padding-top: 5rem;
}

.team-list-indication {
  position: absolute;
  right: 0;
  bottom: 3.5rem;
}

.team-list-indication-current {
  height: 2.375rem;
}

.team-item {
  width: 15rem;
}

.team-icon {
  position: relative;
  width: 8.75rem;
  height: 8.75rem;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0 0.625rem 2.25rem 0.1875rem rgba(57, 156, 255, 0.15);
  box-shadow: 0 0.625rem 2.25rem 0.1875rem rgba(57, 156, 255, 0.15);
}

.team-icon::after {
  content: '';
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  z-index: 9999;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.1875rem solid #399CFF;
}

.team-icon-item {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.team-icon-item > img {
  width: 100%;
  height: auto;
}

.team-icon-item-show {
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  z-index: 999;
  top: 0;
}

.team-icon-item-hidden {
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  z-index: 99;
  top: 8.75rem;
}

.team-title {
  position: relative;
  margin-top: 1.875rem;
  width: 100%;
  height: 1.25rem;
  overflow: hidden;
  font-size: 1rem;
  color: #399CFF;
  text-align: center;
}

.team-title-item {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.team-title-item-show {
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  z-index: 999;
  top: 0;
}

.team-title-item-hidden {
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  z-index: 99;
  top: 1.25rem;
}

/********** 技术改变服务 **********/

.change-list-li::after,
.change-list-li::before {
  content: '';
  position: absolute;
  top: 1.5rem;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background: #B9DAFF;
}

.change {
  padding: 6.25rem 0;
  overflow: hidden;
}

.change-list {
  width: 65.625rem;
  margin: 0 auto;
}

.change-list-item {
  width: 11.875rem;
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.change-list-icon {
  padding-bottom: 0.625rem;
}

.change-list-icon > img {
  width: 100%;
  height: auto;
}

.change-list-li {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 1.25rem 0;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  color: #666;
}

.change-list-li::after {
  right: -0.875rem;
}

.change-list-li::before {
  left: -0.875rem;
}