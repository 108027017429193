.swiper {
  position: relative;
  overflow: hidden;
}

.swiper-wrapper img {
  width: 100%;
}

.pagination-block {
  position: absolute;
  z-index: 9999;
  left: 0;
  bottom: 0;
}

.pagination-block img {
  width: 100%;
}

.pagination-wrapper {
  position: relative;
}

.pagination-wrapper img {
  width: 100%;
}

.pagination-wrapper .pagination {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}

.pagination-wrapper .pagination::after {
  display: inline-block;
  vertical-align: middle;
  content: '';
  height: 100%;
}

.swiper-pagination-switch {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin: 0 0.125rem;
  padding: 0.1875rem;
  cursor: pointer;
  border: 0.0625rem solid transparent;
}

.swiper-pagination-switch::after {
  content: '';
  border: 0.0625rem solid #399CFF;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.swiper-active-switch {
  border: 0.0625rem solid #399CFF;
}

.swiper-active-switch::after {
  background: #399CFF;
}