.product-header {
  margin: 0;
  padding: 0;
}

.product-tabbar {
  width: 100%;
  height: 27.5rem;
  margin: 0 auto;
  background-image: url('../../assets/img/productimg/bn_produce.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.product-conten-top {
  width: 75rem;
  margin: 0 auto;
}

.product-conten-top ul {
  margin-top: 6.1875rem;
  height: 13.4375rem;
  border-bottom: 0.0625rem solid rgba(57, 156, 255, 0.2);
}

.product-conten-top ul li {
  text-align: center;
}

.product-conten-top ul li.lione {
  letter-spacing: 0.0625rem;
  height: 0.875rem;
  font-size: 1rem;
  color: #999999;
  line-height: 0.875rem;
  margin-bottom: 1.5625rem;
}

.product-conten-top ul li.litwo {
  height: 2.125rem;
  font-size: 2.125rem;
  color: #292929;
  line-height: 2.125rem;
  margin-bottom: 1.25rem;
}

.product-conten-top ul li.lithree {
  margin-bottom: 1.25rem;
}

.product-conten-top ul li.lithree span {
  display: inline-block;
  width: 3.6875rem;
  height: 1.75rem;
  background: url('../../assets/img/productimg/title.png');
}

.product-conten-top ul li.lifour {
  height: 2.125rem;
  color: #999999;
  font-size: 0.875rem;
  margin-bottom: 5.0625rem;
}

.product-conten-top ul li.lifour p {
  height: 0.875rem;
}

.product-conten-middle {
  margin-top: -9.375rem;
  margin-bottom: -12.5rem;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.product-conten-middle ul {
  width: 77rem;
  min-width: 77rem;
  margin: -0.9375rem auto;
}

.product-conten-middle ul li {
  height: 23.75rem;
}

.product-conten-middle ul li div.computedWidth {
  padding: 0.9375rem;
}

.product-conten-middle ul li div p img {
  -webkit-box-shadow: darkgrey 0.625rem 0.625rem 1.875rem 0.3125rem;
  box-shadow: darkgrey 0.625rem 0.625rem 1.875rem 0.3125rem;
  margin-top: -0.3125rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}

.product-conten-middle ul li p {
  -webkit-box-shadow: transparent 0 0 0.3125rem 0.1875rem;
  box-shadow: transparent 0 0 0.3125rem 0.1875rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.product-conten-middle ul li p.p7 {
  -webkit-box-shadow: rgba(150, 150, 150, 0.2) 0 0 0.9375rem 0.1875rem;
  box-shadow: rgba(150, 150, 150, 0.2) 0 0 0.9375rem 0.1875rem;
}

.product-conten-bottom {
  margin: 3.75rem auto 7.5rem auto;
  width: 75rem;
  border-top: 0.0625rem solid rgba(40, 40, 40, 0.1);
}

.product-conten-bottom P {
  position: relative;
  top: -6.25rem;
  margin: 2.5rem auto 7.5rem auto;
  width: 11.4375rem;
  height: 1.1875rem;
  font-size: 1.125rem;
  font-family: "Microsoft YaHei";
  color: #939393;
  text-align: center;
}

span.mask {
  display: inline-block;
  position: absolute;
  top: -5;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 70%;
  height: 23.75rem;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition: 1s;
  transition: 1s;
  visibility: visible;
}

span.maskh {
  display: inline-block;
  position: absolute;
  top: -5;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 23.75rem;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition: 1s;
  transition: 1s;
  visibility: visible;
}

span.maskb {
  display: inline-block;
  position: absolute;
  top: -5;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 23.75rem;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition: 1s;
  transition: 1s;
  visibility: visible;
}

i {
  display: block;
}

.masklay {
  width: 100%;
  height: 23.75rem;
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  overflow: hidden;
}

i.showTop {
  position: relative;
  width: 3.75rem;
  height: 0.25rem;
  left: 1.875rem;
  top: 3.25rem;
  background-color: #092F69;
  border-radius: 0.125rem;
}

i.showTitle {
  position: relative;
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
  width: 22.5rem;
  height: 1.6875rem;
  left: 3.125rem;
  top: 5.25rem;
  font-size: 1.625rem;
  font-weight: 400;
  font-family: 'MicrosoftYaHei';
  color: #092F69;
}

i.showEnglishTitle {
  position: relative;
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  width: 19.0625rem;
  height: 1rem;
  white-space: nowrap;
  left: 5.625rem;
  top: 6.0625rem;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: 'MicrosoftYaHei';
  color: #092F69;
}

i.showBottom {
  position: absolute;
  width: 2.25rem;
  height: 1.25rem;
  bottom: 1.25rem;
  right: 1.4375rem;
  font-size: 0.875rem;
  color: #092F69;
  background: url('../../assets/img/productimg/8.png');
  background-size: 2.25rem 1.25rem;
}

.product-conten-middle ul li p:hover span.mask {
  width: 0;
  visibility: hidden;
}

.product-conten-middle ul li p:hover span.maskh {
  height: 0;
  visibility: hidden;
}

.product-conten-middle ul li p:hover span.maskb {
  bottom: 28.125rem;
  visibility: hidden;
}

.firstImgStyle {
  width: 49.375rem;
  height: 23.75rem;
}

.secondImgStyle {
  width: 23.75rem;
  height: 23.75rem;
}

.thirdImgStyle {
  width: 75rem;
  height: 23.75rem;
}