.contact-us {
  width: 100%;
  height: auto;
  background: #FFFFFF;
  text-align: center;
  margin: 0 auto;
}

.contact-tabbar {
  width: 100%;
  height: 27.5rem;
  margin: 0 auto;
  background-image: url('../../assets/img/contact/bn_contact.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-en {
  padding-top: 6.1875rem;
  line-height: 1;
  letter-spacing: 0.1875rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

.contact-zh {
  line-height: 1;
  margin-top: 1.5625rem;
  font-size: 2.125rem;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
}

.pic-title {
  height: 1.75rem;
  margin-top: 1.25rem;
  /* margin-bottom: 96px; */
}

.pic-title img {
  width: 3.75rem;
  height: auto;
}

.contact-info {
  position: relative;
  /* height: 450px; */
  padding-top: 5.625rem;
}

.dot-line {
  position: absolute;
  top: 10.4375rem;
  z-index: 0;
  width: 100%;
  height: 0.125rem;
  /* background-image:
    linear-gradient(
      to right,
      rgba(35, 87, 235, 1) 0%,
      rgba(35, 87, 235, 1) 50%,
      transparent 50%
    );
  background-size: 8px 1px;
  background-repeat: repeat-x; */
  border-top: 0.15625rem dotted rgba(35, 87, 235, 0.3);
}

.list {
  display: inline-block;
  width: 18.75rem;
  /* height: 229px; */
  vertical-align: bottom;
  margin: 0 3.125rem;
}

.list>.contactIcon {
  margin-top: 1.875rem;
  margin-bottom: 1.375rem;
  /* width: 100%; */
}

.list>.contactIcon img {
  display: inline-block;
  position: relative;
  width: 1.25rem;
  height: auto;
}

.list>.contactIcon>img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  border-radius: 50%;
  background-color: #fff;
}

.list>.list-icon img {
  width: 1.875rem;
  height: auto;
}

.content0 {
  height: 1.25rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: rgba(57, 156, 255, 1);
  line-height: 1.25rem;
}

.content1 {
  height: 3.5rem;
  margin-top: 3.75rem;
  margin-bottom: 1.125rem;
  font-weight: 400;
  color: rgba(66, 66, 66, 1);
}

.content1>div {
  margin-top: 1.25rem;
}

.tel {
  line-height: 1.25rem;
  font-size: 1.75rem;
}

.mail {
  font-size: 1.375rem;
  line-height: 1.25rem;
}

.addr {
  font-size: 1.375rem;
  line-height: 2.125rem;
}

.content1>p {
  margin-top: 1.125rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 1.25rem;
}

.map {
  margin-top: 7.5rem;
  width: 100%;
  height: 37.5rem;
}

.bottom-box {
  padding-bottom: 8.75rem;
  padding-top: 7.5rem;
}

.common-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  width: 21.25rem;
  padding: 2.5rem 2.1875rem;
  /* border: 1px #000 solid; */
  border-radius: 0.5rem;
  text-align: center;
  vertical-align: bottom;
  margin: 0 0.3125rem;
}

.common-box:hover {
  -webkit-box-shadow: 0 0.1875rem 1.125rem 0.125rem rgba(10, 79, 220, 0.16);
  box-shadow: 0 0.1875rem 1.125rem 0.125rem rgba(10, 79, 220, 0.16);
}

.block-title {
  font-size: 1.625rem;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.block-content {
  padding: 1.875rem 0;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.block-jump {
  text-align: center;
}

.block-jump>a {
  display: inline;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(57, 156, 255, 1);
}